import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';

//JWT 
import { JwtModule} from '@auth0/angular-jwt';
export function tokenGetter() {
  return localStorage.getItem("token");
}

//Common Angular Mat Module 
import { MatDialogModule } from '@angular/material/dialog';
import { SuccessDialogComponent } from './layouts/dialogs/success-dialog/success-dialog.component';
import { environment } from 'src/environments/environment';

//Image Compress
import { NgxImageCompressService } from 'ngx-image-compress';

//Firebase
import { AngularFireModule } from '@angular/fire';
import {
  AngularFireStorageModule
} from "@angular/fire/storage";
import { NgInitDirective } from './_directive/refresh-directive.directive';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.client],
        disallowedRoutes: [environment.client + "/#/login"],
        authScheme: "",
        throwNoTokenError: true,
        skipWhenExpired: true,
      },
    }),
    MatDialogModule,
    AngularFireModule.initializeApp(environment.firebase, 'TrashBackAdmin'),
    AngularFireStorageModule // Only required for storage features
  ],
  entryComponents: [SuccessDialogComponent],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    SuccessDialogComponent,
    NgInitDirective
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
