// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://trashback.in:8443',
  client: 'http://localhost:4200',
  firebase: {
    apiKey: "AIzaSyBezCl-Gg3tuH9BusIAyUDWQadUbakRS58",
    authDomain: "trashbackadmin.firebaseapp.com",
    databaseURL: "https://trashbackadmin.firebaseio.com",
    projectId: "trashbackadmin",
    storageBucket: "trashbackadmin.appspot.com",
    messagingSenderId: "892941386140",
    appId: "1:892941386140:web:74e58ad3d23e8c0dc30859",
    measurementId: "G-Z9EDF1PGJ1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
